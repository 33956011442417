import { platformStore } from '~/stores/platform'
import { mythosStore } from '~/stores/mythos'


export enum PlatformName {
  REDDIT = 'REDDIT',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  LINKEDIN = 'LINKEDIN',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TITOK = 'TITOK',
}

export enum PlatformMedium {
  AD_COMMENT = 'AD_COMMENT',
  POST_COMMENT = 'POST_COMMENT',
  DIRECT_MESSAGE = 'DIRECT_MESSAGE',
}

export class Platform {
  id: number | null
  platform: PlatformName | null
  medium: PlatformMedium | null
  label: string | null
  state: string | null
  account_ref: string | null
  auth_code: string | null

  constructor(id: number | null, platform: PlatformName | null, medium: PlatformMedium | null, label: string | null, state: string | null, account_ref: string | null, auth_code: string | null) {
    this.id = id
    this.platform = platform
    this.medium = medium
    this.label = label
    this.state = state
    this.account_ref = account_ref
    this.auth_code = auth_code
  }

  async initialize() {
    const uStore = userStore()
    const config = useRuntimeConfig()

    const req = await fetch(`${config.public.MYTHOS_RUNNER}/platform/platforms`, {
      headers: { idToken: uStore.user?.idToken || '' }
    })
    const res = await req.json()

    if (res && res.platforms) {
      const pStore = platformStore()
      pStore.platforms = []

      res.platforms.forEach((p: Platform) => {
        pStore.platforms.push(new Platform(p.id, p.platform, p.medium, p.label, p.state, p.account_ref, p.auth_code))
      })
    }
  }

  async initiateIntg(window: Window, idToken: string, platform: PlatformName) {
    const mStore = mythosStore()
    const config = useRuntimeConfig()

    // To let the UI know that an integration is under way (for the spinner in step 1, etc.)
    mStore.integrationInitiatedFor = PlatformName[platform]

    // Fetch the Authorization URL
    const req = await fetch(`${config.public.MYTHOS_RUNNER}/platform/integrate/${platform}`, {
      method: 'POST',
      headers: {
        idToken,
      },
    })
    const res = await req.json()

    // Redirect users to the Auth URL
    if (res && res.redirect_url) {
      window.open(res.redirect_url, '_blank')
    }
    else {
      mStore.alertMessage = 'Oops, someting went wrong, please try again.'
      setTimeout(() => {
        mStore.alertMessage = null
      }, 2000)
    }
  }
}

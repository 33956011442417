import { Workflow } from '~/src/workflow/workflow'
import { mythosStore } from './mythos'

export const workflowStore = defineStore('workflows', {
  state: () => ({
    workflows: <Workflow[]>[],
    setup: {
      name: <string>'',
      location: <string>'',
      gender: <Array<any>>[],
      type: <Array<any>>[],
      personality: <Array<any>>[],
      interests: <Array<any>>[],
      ads: <Array<any>>[], // TODO: define Ad type and use it here
      age_range: <string>'18 - 45',
      brand_assets: <Array<string>>[],
      brand_guidelines: <Array<string>>[],
      brand_terminology: <string>'',
      additional_comments: <string>'',
      threading_enabled: <boolean>false,
      flow: <any>null
    },
    duplicate: {
      workflow_id: <string>'',
      flow_id: <string>''
    }
  }),
  actions: {
    reset_setup() {
      this.setup = {
        name: '',
        location: '',
        gender: [],
        type: [],
        personality: [],
        interests: [],
        ads: [],
        age_range: '18 - 45',
        brand_assets: [],
        brand_guidelines: [],
        brand_terminology: '',
        additional_comments: '',
        threading_enabled: false,
        flow: null
      }
    },
    async create_workflow() {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const req = await fetch(`${config.public.MYTHOS_RUNNER}/workflow/create`, {
        headers: { idToken: uStore.user?.idToken || '' },
        method: 'POST',
        body: JSON.stringify(this.setup),
      })
      const res = await req.json()
      
      if (res && res.status !== 201) {
        const mStore = mythosStore()
        mStore.create_alert('Sorry, could not create that workflow, please try again.')
      }
      
      this.reset_setup()

      const workflow = new Workflow(null, null, null, null, new Date(), new Date(), null, null, null)
      await workflow.initialize()
      return res
    },

    async duplicate_workflow() {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const req = await fetch(`${config.public.MYTHOS_RUNNER}/workflow/duplicate`, {
        headers: { idToken: uStore.user?.idToken || '' },
        method: 'POST',
        body: JSON.stringify(this.duplicate),
      })
      const res = await req.json()
      if (res && res.status !== 200) {
        const mStore = mythosStore()
        mStore.create_alert('Sorry, could not duplicate that workflow, please try again.')
      }
      return res
    },

    async toggle_workflow(workflow_id: string) {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const req = await fetch(`${config.public.MYTHOS_RUNNER}/workflow/toggle`, {
        headers: { idToken: uStore.user?.idToken || '' },
        method: 'POST',
        body: JSON.stringify({ workflow_id }),
      })
      const res = await req.json()
      if (res && res.status !== 200) {
        const mStore = mythosStore()
        mStore.create_alert('Sorry, could not toggle that workflow, please try again.')
      }
      return res
    },

    async delete_workflow(workflow_id: string) {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const req = await fetch(`${config.public.MYTHOS_RUNNER}/workflow/delete`, {
        headers: { idToken: uStore.user?.idToken || '' },
        method: 'POST',
        body: JSON.stringify({ workflow_id }),
      })
      const res = await req.json()
      if (res && res.status !== 200) {
        const mStore = mythosStore()
        mStore.create_alert('Sorry, could not delete that workflow, please try again.')
      }
      return res
    }
  },
})

// import type { WorkflowStatus } from '@prisma/client'
import { Platform } from '../platform/platform'
import { Post } from '../platform/post'
import { workflowStore } from '~/stores/workflow'


export class Workflow {
  id: number | null
  workflow_ref: string | null
  account_ref: string | null
  name: string | null
  created: Date
  updated: Date
  status: any | null
  posts: Post[]
  platforms: Platform[]
  flow: any | null

  constructor(id: number | null, account_ref: string | null, workflow_ref: string | null, name: string | null, created: Date, updated: Date, status: any | null, posts: Post[] | null, flow: any | null) {
    this.id = id
    this.account_ref = account_ref
    this.workflow_ref = workflow_ref
    this.name = name
    this.created = created
    this.updated = updated
    this.status = status
    this.posts = posts || []
    this.platforms = []
    this.flow = flow
  }

  async initialize() {
    const uStore = userStore()
    const config = useRuntimeConfig()
    const req = await fetch(`${config.public.MYTHOS_RUNNER}/workflow/workflows`, {
      headers: { idToken: uStore.user?.idToken || '' }
    })
    const res = await req.json()

    if (res && res.workflows) {
      const workflow_store = workflowStore()
      workflow_store.workflows = []

      res.workflows.forEach((w: Workflow) => {
        // Posts
        w.posts = w.posts.map((p: Post) => {
          const tmp = new Platform(p.platform.id, p.platform.platform, p.platform.medium, p.platform.label, p.platform.state, p.platform.account_ref, p.platform.auth_code)
          // If it doesn't already exist then add it
          // if (!w.platforms.map(_ => _.id).includes(p.platform.id))
          //   w.platforms.push(tmp)
          return new Post(p.id, p.post_id, p.body, p.title, tmp)
        })

        // Workflow
        w.created = new Date(w.created)
        w.updated = new Date(w.updated)

        workflow_store.workflows.push(w)
      })
    }
  }
}

import { defineStore } from 'pinia'
import { mythosStore } from './mythos'
import type { FlowModuleAction } from '~/src/flow/module'
import type { FlowNode } from '~/src/flow/flow'
import { Workflow } from '~/src/workflow/workflow'
import { Flow, FlowNodeIntegration } from '~/src/flow/flow'
import Ajv from "ajv"
import { ADD_RULE_NODE_TYPE, FlowUI } from "~/src/flow/flowUI"

export const flowStore = defineStore('flows', {
  state: () => ({
    flowUI: <FlowUI>{},
    current_node: {},
    flowNodes: <FlowNode[]>[],
    flowNodeIntegrations: <FlowNodeIntegration[]>[],
    flowModuleActions: <FlowModuleAction[]>[],
    setup: {
      name: <string>'',
      nodes: <Array<any>>[],
    },
    duplicate: {
      flow_id: <string>'',
    }
  }),
  actions: {
    reset_setup() {
      this.setup = {
        name: '',
        nodes: []
      }
    },
    async create_flow() {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const req = await fetch(
        `${config.public.MYTHOS_RUNNER}/flow/create`, 
        {
          headers: { idToken: uStore.user?.idToken || '' },
          method: 'POST',
          body: JSON.stringify(this.setup)
        }
      )
      const res = await req.json()
      if (res && res.status !== 201) 
      {
        const mStore = mythosStore()
        mStore.create_alert('Sorry, could not create that flow, please try again.')
      }

      this.reset_setup()

      const f = new Flow(null, null, null, null, new Date(), new Date())
      await f.initialize()

      return res
    },
    async duplicate_flow() {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const req = await fetch(`${config.public.MYTHOS_RUNNER}/flow/duplicate`, {
        headers: { idToken: uStore.user?.idToken || '' },
        method: 'POST',
        body: JSON.stringify(this.duplicate),
      })
      const res = await req.json()

      if (res && res.status !== 200) {
        const mStore = mythosStore()
        mStore.create_alert('Sorry, could not duplicate that flow, please try again.')
      }

      const f = new Flow(null, null, null, null, new Date(), new Date())
      await f.initialize()

      return res
    },
    async update_flow(flow: Flow) {
      const uStore = userStore()
      const config = useRuntimeConfig()
      const req = await fetch(
        `${config.public.MYTHOS_RUNNER}/flow/update`, 
        {
          headers: { idToken: uStore.user?.idToken || '' },
          method: 'POST',
          body: JSON.stringify({ ...this.setup, flow }),
        }
      )
      const res = await req.json()

      if (res && res.status !== 200) {
        const mStore = mythosStore()
        mStore.create_alert('Sorry, could not update that flow, please try again.')
      }

      this.reset_setup()

      const workflow = new Workflow(null, null, null, null, new Date(), new Date(), null, null, null)
      await workflow.initialize()
      const f = new Flow(null, null, null, null, new Date(), new Date())
      await f.initialize()

      return res
    },
    async validate_flow() {
      const ajv = new Ajv({ strict: false });
      const nodes_to_validate = this.setup.nodes.filter((n) => n.data.sub_type !== ADD_RULE_NODE_TYPE)
      
      for (let i = 0; i < nodes_to_validate.length; i++) {
        const node = nodes_to_validate[i]
        const schema = node.data.flow_module_action?.config?.properties.attributes || {}
        const data = node.data.state?.attributes || {}

        try
        {
          // A rule node is a json object and we're only validating that its a valid json object
          // TODO - check if the rule is valid
          if (node.data.sub_type === "rule") 
          {
            JSON.parse(data)
          }
          else 
          {
            const validate = ajv.compile(schema)
            if (!validate(data)) return { success: false, type: node.data.type, node: node, node_name: node.data.label }
          }
        }
        catch (e)
        {
          console.error(e)
          return { success: false, type: node.data.type, node: node, node_name: node.data.label }
        }
      }

      return { success: true }
    }
  }
})
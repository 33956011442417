export interface IEdge {
  id: string
  source: string
  target: string
  style: { stroke: string }
  type: string
}

export class FlowEdge {
  id: string
  source: string
  target: string
  style: { stroke: string }
  type: string

  constructor(
    source: string,
    target: string,
    style: { stroke: string } = { stroke: 'rgb(147 51 234)' },
    type: string = "default"
  ) {
    this.id = `e${source}-${target}`
    this.source = source
    this.target = target
    this.style = style
    this.type = type
  }
  
  to_json(): IEdge {
    return {
      id: this.id,
      source: this.source,
      target: this.target,
      style: this.style,
      type: this.type
    }
  }
}


// [
//   {
//     id: `e${TRIGGER_NODE_ID}-${ACTION_NODE_ID}`,
//     source: TRIGGER_NODE_ID,
//     target: ACTION_NODE_ID,
//     style: { stroke: "rgb(147 51 234)" },
//     type: "default",
//   }
// ]
// id: string
// source: string
// target: string
// style: string
// type: string

// constructor() {

// }

// static createEdge(source: string, target: string): IEdge {
//   
// }
import { FlowNode } from './flow'
import { ROUTER_PATH_SUBTYPE, ROUTER_SUBTYPE } from './flowUI'
import type { FlowModuleAction } from './module'

export type Position = {
  x: number
  y: number
}

export type NodeData = {
  sub_type: string
  label: string
  source: string
  detail: string
  type: string
  module?: string
  icon: string
  state: any
  parent_id: string | null
  prev_node_id: string | null
  next_node_id: string | null
  content: any
  flow_module_action: FlowModuleAction | null
}

export interface IFlowNode {
  id: string
  position: Position
  flow_node_id: string | null  
  type: string
  data: NodeData
}

export class UIFlowNode {
  id: string
  type: string
  position: Position
  flow_node_id: string | null
  data: NodeData

  constructor(id?: string, position?: Position, flow_node_id?: string | null, data?: NodeData) 
  {
    this.id = id || ''
    this.type = "custom"
    this.position = position || { x: 0, y: 0 }
    this.flow_node_id = flow_node_id || ''
    if (data) {
      this.data = {
        ...data,
        sub_type: data.sub_type || "",
      }
    } else {
      this.data = {
        state: {},
        type: "action",
        parent_id: null,
        prev_node_id: null,
        next_node_id: null,
        sub_type: "",
        content: "", 
        label: "",
        detail: "",
        icon: "",
        source: "",
        flow_module_action: null
      }
    }
  }

  create_instance_from_node(node: FlowNode, action_position: Position, data: NodeData): UIFlowNode 
  {
    const action_id = String(node.id)
    const flow_node_id = String(node.id)
    let icon = `/image/social/${node.flow_module_action?.module_type?.module?.replace(/\s+/g, '_').toLowerCase()}.png`
    // different icon for router and router path
    // since both have same module type but different sub type
    if (node.module_sub_type === ROUTER_PATH_SUBTYPE) {
      icon = '/image/social/route.png'
    }
    const label = node.module || ''

    return new UIFlowNode(
      action_id,
      action_position,
      flow_node_id,
      {
        ...data,
        state: node.state,
        content: node.flow_module_action, 
        flow_module_action: node.flow_module_action,
        sub_type: node.flow_module_action?.module_sub_type || "",
        label: `${flow_node_id}. ${label}`,
        icon: icon
      }
    )
  }

  duplicate(): UIFlowNode 
  {
    return new UIFlowNode(this.id, JSON.parse(JSON.stringify(this.position)), this.flow_node_id, this.data)
  }

  to_json() 
  {
    return {
      id: this.id,
      type: this.type,
      position: this.position,
      flow_node_id: this.flow_node_id,
      data: this.data,
    }
  }
}


// [
//   {
//     id: TRIGGER_NODE_ID,
//     type: "custom",
//     position: { x: 150, y: 150 },
//     parent_id: null,
//     data: {
//       node_type: TRIGGER_NODE_TYPE,
//       label: "Add Trigger",
//       detail: "Events that start the workflow",
//       type: "trigger",
//       icon: "/image/flow/plus.png",
//     },
//   },
//   {
//     id: ACTION_NODE_ID,
//     type: "custom",
//     position: { x: 400, y: 150 },
//     parent_id: null,
//     data: {
//       node_type: ACTION_NODE_TYPE,
//       label: "Add Action",
//       source: TRIGGER_NODE_ID,
//       detail: "Events performed after trigger",
//       type: "action",
//       icon: "/image/flow/plus.png",
//     },
//   }
// ]
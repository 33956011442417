import { Flow, FlowNode } from './flow'
import { type IFlowNode, type Position, UIFlowNode } from './flowNode'
import { type IEdge, FlowEdge } from './flowEdge'
import { flowStore } from "~/stores/flow"
import { random_key } from '../utils/random'

export const TRIGGER_NODE_ID = "1"
export const ACTION_NODE_ID = "2"

export const ADD_TRIGGER_NODE_TYPE = "add_trigger"
export const ADD_ACTION_NODE_TYPE = "add_action"
export const ADD_RULE_NODE_TYPE = "router"

export const ROUTER_SUBTYPE = "router"
export const ROUTER_PATH_SUBTYPE = 'router_path'

const MAX = 999, MIN = 0

const ADD_TRIGGER_NODE = new UIFlowNode(TRIGGER_NODE_ID, { x: 150, y: 150 }, null, { sub_type: ADD_TRIGGER_NODE_TYPE, label: "Add Trigger", module: "Add Trigger", source: "", icon: "/image/flow/plus.png", detail: "Events that trigger the workflow", parent_id: null, prev_node_id: null, next_node_id: ACTION_NODE_ID, type: "trigger", content: {}, state: null, flow_module_action: null })
const ADD_ACTION_NODE = new UIFlowNode(ACTION_NODE_ID, { x: 400, y: 150 }, null, { sub_type: ADD_ACTION_NODE_TYPE, label: "Add Action", module: "Add Action", source: "", icon: "/image/flow/plus.png", detail: "Events performed after trigger", type: "action", content: {}, parent_id: TRIGGER_NODE_ID,  prev_node_id: null, next_node_id: null, state: null, flow_module_action: null })


export class FlowUI 
{
  flow: Flow | null
  flow_nodes: FlowNode[]
  last_trigger_node: IFlowNode | null
  event_node: IFlowNode | null
  nodes: Array<IFlowNode>
  edges: Array<IEdge>

  constructor() {
    this.flow_nodes = []

    // these are the 'add action' and 'add trigger' nodes
    this.nodes = [
      // new UIFlowNode(TRIGGER_NODE_ID, { x: 150, y: 150 }, null, { sub_type: ADD_TRIGGER_NODE_TYPE, label: "Add Trigger", module: "Add Trigger", source: "", icon: "/image/flow/plus.png", detail: "Events that trigger the workflow", parent_id: null, type: "trigger", content: {}, state: null }).to_json(), 
      // new UIFlowNode(ACTION_NODE_ID, { x: 400, y: 150 }, null, { sub_type: ADD_ACTION_NODE_TYPE, label: "Add Action", module: "Add Action", source: "", icon: "/image/flow/plus.png", detail: "Events performed after trigger", type: "action", content: {}, parent_id: null, state: null }).to_json()
    ]

    // there are the links between the 'add action' and 'add trigger' on init
    this.edges = [/* new FlowEdge(TRIGGER_NODE_ID, ACTION_NODE_ID) */]
    
    this.event_node = null
    this.last_trigger_node = null
    this.flow = null
  }

  initialize_flow_ui() 
  {
    this.nodes = [
      new UIFlowNode(TRIGGER_NODE_ID, { x: 150, y: 150 }, null, { sub_type: ADD_TRIGGER_NODE_TYPE, label: "Add Trigger", module: "Add Trigger", source: "", icon: "/image/flow/plus.png", detail: "Events that trigger the workflow", parent_id: null,  prev_node_id: null, next_node_id: ACTION_NODE_ID, type: "trigger", content: {}, state: null, flow_module_action: null }).to_json(), 
      new UIFlowNode(ACTION_NODE_ID, { x: 400, y: 150 }, null, { sub_type: ADD_ACTION_NODE_TYPE, label: "Add Action", module: "Add Action", source: "", icon: "/image/flow/plus.png", detail: "Events performed after trigger", type: "action", content: {}, parent_id: TRIGGER_NODE_ID,  prev_node_id: null, next_node_id: null, state: null, flow_module_action: null }).to_json()
    ]
    this.edges = [new FlowEdge(TRIGGER_NODE_ID, ACTION_NODE_ID)]
    this.last_trigger_node = this.nodes[0]
  }

  find_nodes_from_flow(flow: any): [FlowNode[], FlowNode] 
  {
    const fStore = flowStore()
    this.flow_nodes = fStore.flowNodes?.filter(node => node.flow?.id === flow.id)
    const triggers = this.flow_nodes.filter(node => node.module_type === "TRIGGER")
    const first_node = this.flow_nodes.find(node => node.id == triggers[0]?.next_node?.id)
    if (!first_node) {
      throw createError('Error while finding first node: Flow dow not contain an action!')
    }
    return [triggers, first_node]
  }

  construct_flow_ui(flow: any) 
  {    
    // find all the nodes in the flow separately
    const [triggers, first_action] = this.find_nodes_from_flow(flow)

    ADD_TRIGGER_NODE.data.next_node_id = String(first_action?.id)
    // push the 'Add Trigger' button into the workflow
    this.nodes.push(ADD_TRIGGER_NODE.to_json())

    if (triggers.length === 0) return createError('Flow has no triggers!')

    // loop through & attach triggers and actions separately
    // as each type is attached differently to the flow
    for (let idx in triggers)
    {
      this.attach_trigger_to_flow(triggers[idx])
    }

    this.attach_action_to_flow(first_action, null)
    // when we attach all trigger and action nodes
    // we then connect them with edges based on next node and parent node
    this.connect_nodes_with_edges()
  }

  connect_nodes_with_edges()
  {
    // create edges from scratch based on nodes
    // use next_node_id to connect the nodes
    this.edges = []
    this.nodes.map(node => {
      if(node.data.next_node_id) 
      {
        this.edges.push(new FlowEdge(node.id, node.data.next_node_id))
      }
      // in case node is router, connect all its children
      // find children based on parent id
      else if(node.data.sub_type === ROUTER_SUBTYPE) {
        let nextNodes = this.nodes.filter((n) => n.data.parent_id === node.id)
        nextNodes.map(nextNode => {
          this.edges.push(new FlowEdge(node.id, nextNode.id))
        })
      }
    })
  }

  attach_trigger_to_flow(node: FlowNode) 
  {
    const trigger_position = {
      x: this.last_trigger_node?.position.x || 150, 
      y: (this.last_trigger_node?.position.y || 150) + 180,
      ...node.position
    }

    let data = {
      type: "trigger", parent_id: null,  prev_node_id: null, next_node_id: String(node.next_node?.id), sub_type: "", detail: "", 
      source: "", state: {}, content: "",  label: "", icon: "", flow_module_action: null
    }
    let new_trigger_node = new UIFlowNode().create_instance_from_node(node, trigger_position, data)

    this.nodes.push(new_trigger_node.to_json())

    this.nodes.forEach((node) => {
      if (node.data.parent_id === TRIGGER_NODE_ID) {
        node.data.parent_id = new_trigger_node.id
      }
    })

    this.last_trigger_node = new_trigger_node.duplicate()
    return new_trigger_node
  }

  attach_action_to_flow(flownode_being_attached: FlowNode, last_action_node: UIFlowNode | null) 
  {
    if (flownode_being_attached.module_sub_type === ROUTER_PATH_SUBTYPE && last_action_node?.position) 
    {
      // find all nodes that have last_action_node as parent
      // in case of router it will help us determine the no of branches
      const num_of_branches = this.nodes.filter((node) => node.data.parent_id === String(last_action_node?.id)).length
      last_action_node.position.y = num_of_branches * 150 + last_action_node.position.y
    }

    const action_position = {
      x: (last_action_node?.position.x || 150) + 300, 
      y: last_action_node?.position.y || 150,
      ...flownode_being_attached.position // this is position that was set by the user which is stored in db
    }

    // create a new action node of a type
    let data = {
      type: "action", 
      // if last action node is null, this is the first action that comes after trigger
      parent_id: last_action_node?.id || this.last_trigger_node?.id || null, prev_node_id: null, next_node_id: null,
      sub_type: "", detail: "", source: "", state: {}, content: "",  label: "", icon: "", flow_module_action: null
    }

    let new_action_node = new UIFlowNode().create_instance_from_node(
      flownode_being_attached, action_position, data
    )

    // if this is the first action node created after a trigger
    // set the next_node_id of all triggers to this node
    if(last_action_node?.data.type === "trigger") {
      this.nodes.filter(node => node.data.type === "trigger").map(trigger => {
        trigger.data.next_node_id = new_action_node.id
      })
    }
    
    // push the new action node to the flow
    this.nodes.push(new_action_node.to_json())

    // if there are nodes with 'add trigger' as a parent_id, change to the last triggers
    this.nodes.forEach((node) => {
      if (node.data.parent_id === TRIGGER_NODE_ID && this.last_trigger_node) {
        node.data.parent_id = this.last_trigger_node.id
      }
    })

    // duplicate(): coz when I change the position for 
    // new_action_node, it shouldn't change the flow
    let new_action_node_dupe = new_action_node.duplicate()
    
    // recursively find and attach the next node
    // if next_node is null -> either there are no nodes ahead
    // or there are branched router rules with this node as parent
    if (flownode_being_attached.next_node == null) 
    {
      // find nodes that have this node as the parent
      const next_nodes = this.find_or_create_next_nodes(flownode_being_attached)

      if (next_nodes.length > 0) 
      {
        for (let idx in next_nodes) 
        {
          // since multiple next nodes possible(router paths)
          // we will keep next_node_id as null for this newly created action node
          // and only set it as parent for the next nodes
          this.attach_action_to_flow(next_nodes[idx], new_action_node_dupe)
        }
      }
      else 
      {
        const add_action_node = this.attach_add_action_node_to_flow(
          (new_action_node_dupe.position.x || 150) + 300, new_action_node_dupe.position.y || 150
        )
        // set next_node_id of the newly created action node to the 'add action' node
        new_action_node.data.next_node_id = add_action_node.id;
      }
    }
    else 
    {
      const next_node = this.flow_nodes.find((n) => n.id === flownode_being_attached.next_node?.id)
      next_node && this.attach_action_to_flow(next_node, new_action_node_dupe)
      // set next_node_id of the newly created action node to the next node
      new_action_node.data.next_node_id = String(next_node?.id);
    }
    return new_action_node
  }

  // find nodes that have this node as the parent
  find_or_create_next_nodes(flownode_being_attached: FlowNode) 
  {
    if (flownode_being_attached.module_sub_type !== ROUTER_SUBTYPE) return [] // if this isn't a router

    // find the existing branches to the router
    const next_nodes = this.flow_nodes.filter(
      (n) => n.parent_node && n.parent_node.id === flownode_being_attached.id
    )

    const PATH_ROUTE = flowStore().flowModuleActions.find(
      (fna) => fna.module_sub_type === ROUTER_PATH_SUBTYPE
    )

    // adding branches to a newly added router
    // if this is an existing router, it will have next_nodes
    // if it is a 'router' subtype and has no children, it is a new router being added
    if (
      PATH_ROUTE && // if path route type exists
      next_nodes.length == 0 // if there are no existing branches
    ) {
      next_nodes.push(
        new FlowNode(
          // Math.floor(Math.random() * (MAX - MIN + 1)) + MIN, 
          Math.max(...this.nodes.map(n => Number(n.id) || 0)) + 1,
          this.flow, 
          'Route', 
          flownode_being_attached.module_type, 
          'router_path', 
          new Date(), 
          new Date(),
          {'attributes': {}},
          null,
          flownode_being_attached,
          '',
          PATH_ROUTE,
          null
        )
      )
      next_nodes.push(
        new FlowNode(
          // Math.floor(Math.random() * (MAX - MIN + 1)) + MIN, 
          Math.max(...this.nodes.map(n => Number(n.id) || 0)) + 2, 
          this.flow, 
          'Route', 
          flownode_being_attached.module_type, 
          'router_path', 
          new Date(), 
          new Date(),
          {'attributes': {}},
          null,
          flownode_being_attached,
          '',
          PATH_ROUTE,
          null
        )
      )
    }

    return next_nodes
  }

  on_add_new_node(
    module: any, module_sub_type: string | null, type: any, state: object = {attributes: {}}
  ) {
    let prev_node_id
    let new_node

    // if this is a router path, set the router node as the parent
    if (module_sub_type === ROUTER_PATH_SUBTYPE) 
    {
      prev_node_id = this.edges.find((edge) => edge.target === this.event_node?.id)?.target
    } 
    else 
    {
      // if this is the first action being added, find the edge that isn't a trigger
      prev_node_id = this.edges.find((edge) => edge.target === this.event_node?.id)?.source
    }
    let prev_ui_node = this.nodes.find((n) => Number(n.id) === Number(prev_node_id))

    // let next_action_id = Math.floor(Math.random() * (MAX - MIN + 1)) + MIN
    let next_action_id = Math.max(...this.nodes.map(n => Number(n.id) || 0)) + 1;

    // set the next_node_id of the prev node to the new node we are about to create
    // if router, ignore since router doesn't have a next_node_id
    if(prev_ui_node && prev_ui_node.data.sub_type !== ROUTER_SUBTYPE) {
      prev_ui_node.data.next_node_id = String(next_action_id)
    }

    // if there is a prev_ui_node, this is an action
    if (module.type == 'ACTION' && prev_node_id && prev_ui_node)
    {
      let prev_node = this.flow_nodes.find((n) => n.id === Number(prev_node_id))

      // remove the 'add action' node from the leading edge IF this is an action being 
      // added to the tail and not an add path operation for a router
      if (this.event_node?.data.sub_type === ADD_ACTION_NODE_TYPE) {
        this.nodes = this.nodes.filter((n) => n.id !== this.event_node?.id)
      }

      new_node = this.attach_action_to_flow(
        new FlowNode(
          next_action_id, 
          this.flow, 
          module.module, 
          module.module_type, 
          module_sub_type, 
          new Date(), 
          new Date(),
          state,
          null,
          prev_node || null,
          '',
          module,
          null
        ), 
        new UIFlowNode(
          prev_ui_node.id, 
          {...prev_ui_node.position}, // makes sure this isn't passed by ref, if pos changes elsewhere, the prev node is affected
          prev_ui_node.flow_node_id, 
          prev_ui_node.data
        )
      )
    }
    else if (module.type == 'TRIGGER')
    {
      // first action node always has a trigger as the parent node
      let first_edge = this.edges.find((edge) => edge.source == this.event_node?.id)

      const next_node = new FlowNode(
        Number(first_edge?.target) || Number(ACTION_NODE_ID),
        this.flow, 
        module.module, 
        module.module_type, 
        module_sub_type, 
        new Date(), 
        new Date(),
        state,
        null,
        null,
        '',
        module,
        null
      )

      new_node = this.attach_trigger_to_flow(
        new FlowNode(
          next_action_id, 
          this.flow, 
          module.module, 
          module.module_type, 
          module_sub_type, 
          new Date(), 
          new Date(),
          state,
          next_node || null,
          null,
          '',
          module,
          null
        )
      )
    }
    // new node so create edges from scratch
    // to avoid any duplicate edges being created
    this.connect_nodes_with_edges()
    return new_node
  }

  remove_node(node_id_to_del: string, is_this_top_action_node: boolean | null)
  {
    // find & delete the node from the flow
    var del_node = this.nodes.find((node) => node.id === node_id_to_del)
    this.nodes = this.nodes.filter((node) => node.id !== del_node?.id)

    if(del_node?.data.type === "trigger") 
    {
      // recompute the last_trigger_node, irrespective of if this the last
      // trigger node being deleted, find the right last trigger
      this.last_trigger_node = this.nodes.filter(
        (node) => node.data.type === "trigger"
      ).pop() || null

      // if this trigger node was the parent of any action node,
      // change the parent to the last trigger node
      this.nodes.map(node => {
        if(node.data.parent_id === del_node?.id){
          node.data.parent_id = this.last_trigger_node?.id || TRIGGER_NODE_ID
        }
      })
      // remove edge and DO NOT remove all nodes following this node 
      this.edges = this.edges.filter((edge) => edge.source !== del_node?.id)
      return
    }

    // find all edges on the tail end of the deleted node
    const next_node_edges = this.edges.filter((edge) => edge.source == del_node?.id)

    for (let idx in next_node_edges)
    {
      // if this is the first action being deleted => is_this_top_action_node: true
      // if this is not the first action being deleted => is_this_top_action_node: null
      this.remove_node(next_node_edges[idx].target, is_this_top_action_node===false?true:null)
    }

    // delete all the edges starting from the next_node
    this.edges = this.edges.filter((edge) => edge.source !== del_node?.id)

    // if the deleted node was a router path node
    // and it was the only child left
    // then remove the parent router node as well
    let lastRouterPath: boolean = false
    let routerId: string | undefined | null = null
    if (del_node?.data.sub_type === ROUTER_PATH_SUBTYPE) {
      routerId = this.edges.find((edge) => edge.target === del_node?.id)?.source
      if(this.edges.filter((edge) => edge.source === routerId).length === 1) {
        lastRouterPath = true
        // remove parent router since last router path also deleted
        this.nodes = this.nodes.filter((node) => node.id !== routerId)
        this.edges = this.edges.filter((edge) => edge.source !== routerId)
      }
    }    

    // if this is the top node AND this isnt a router path OR this is the last router path node
    // attach a 'add action' node
    if (is_this_top_action_node && (del_node?.data.sub_type !== ROUTER_PATH_SUBTYPE || lastRouterPath)) {
      // push an 'add action' into the flow and set it as the target
      const add_action_node = this.attach_add_action_node_to_flow(
        del_node?.position.x || 400, del_node?.position.y || 150
      )

      // replace all edges that lead to the first action node with new node
      this.edges.forEach((edge) => {
        // if this is the last router path node, replace the target with the router node
        // because router must have got deleted earlier
        if(edge.target === (lastRouterPath? routerId : del_node?.id)){
          edge.id = `e${edge.source}-${add_action_node.id}`
          edge.target = add_action_node.id
          let sourceNode = this.nodes.find((node) => node.id === edge.source)
          if(sourceNode) {
            sourceNode.data.next_node_id = add_action_node.id
          }
        }
      })
    }
    else {
      // remove edges leading to this node
      this.edges = this.edges.filter((edge) => edge.target !== del_node?.id)
    }
  }

  attach_add_action_node_to_flow(x: number, y: number) 
  {
    // ADD_ACTION_NODE.id = String(Math.floor(Math.random() * (MAX - MIN + 1)) + MIN)
    ADD_ACTION_NODE.id = random_key(10)
    ADD_ACTION_NODE.position.x = x
    ADD_ACTION_NODE.position.y = y
    this.nodes.push(ADD_ACTION_NODE.duplicate())
    return ADD_ACTION_NODE
  } 

  get_prev_node_output_vars(cur_node: IFlowNode | null): any[] 
  {
    const prev_node_output_vars: any[] = []
    const visited_nodes: string[] = []
    if (!cur_node) return []
  
    const traverse = (node: IFlowNode | null) => {
      if (!node) return
  
      // Find all edges where the current node is the target
      const edges_to_this_node = this.edges.filter((edge) => edge.target === node.id)

      // Iterate over each incoming edge
      edges_to_this_node.forEach(edge_to_this_node => {
        // Find the previous node (source of the edge)
        const prev_node = this.nodes.find((node) => node.id === edge_to_this_node.source)

        if (prev_node && !visited_nodes.includes(prev_node.id)) {
          visited_nodes.push(prev_node.id)
          const _ps = prev_node.data.content?.config?.properties?.output?.properties
          if (_ps) {
            Object.keys(_ps).forEach(key => {
              prev_node_output_vars.push({
                node: prev_node,
                output_prop: _ps[key],
                key: key,
                label: prev_node.id + ': '+key
              })
            })
          }
  
          // Recursively traverse the previous node
          traverse(prev_node)
        }
      })
    }
  
    traverse(cur_node)
    return prev_node_output_vars
  }
}  
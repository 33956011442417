import type { Platform } from './platform'

export class Post {
  id: number
  post_id: string
  title: string
  body: string
  platform: Platform

  constructor(id: number, post_id: string, body: string, title: string, platform: Platform) {
    this.id = id
    this.post_id = post_id
    this.title = title
    this.body = body
    this.platform = platform
  }
}

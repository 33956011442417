import { flowStore } from '~/stores/flow'

export class FlowModule {
  id: number | null
  module: string | null
  config: Object | null

  constructor(id: number | null, module: string | null, config: Object | null) {
    this.id = id
    this.module = module
    this.config = config
  }
}

export class FlowModuleAction {
  id: number | null
  module: string | null
  config: Object | null
  module_type: FlowModule | null
  type: string | null
  icon: string | null
  module_sub_type: string | null

  constructor(
    id: number | null, module: string | null, config: Object | null, 
    module_type: FlowModule | null, type: string | null, icon: string | null,
    module_sub_type?: string | null
  ) {
    this.id = id
    this.module = module
    this.config = config
    this.module_type = module_type
    this.type = type
    this.icon = icon
    this.module_sub_type = module_sub_type || null
  }

  async initialize() {
    const uStore = userStore()
    const config = useRuntimeConfig()
    const req = await fetch(`${config.public.MYTHOS_RUNNER}/flow/module/actions`, {
      headers: { idToken: uStore.user?.idToken || '' }
    })
    const res = await req.json()

    if (res && res.module_actions) {
      const flow_store = flowStore()
      flow_store.flowModuleActions = []

      res.module_actions.forEach((f: FlowModuleAction) => {
        flow_store.flowModuleActions.push(
          new FlowModuleAction(f.id, f.module, f.config, f.module_type, f.type, f.icon, f.module_sub_type)
        )
      })
    }
  }
}
import { FlowModuleAction } from '~/src/flow/module'
import { Platform } from '~/src/platform/platform'


export class Flow {
  id: number | null
  name: string | null
  state: Object | null
  created: Date
  updated: Date
  account_ref: string | null

  constructor(id: number | null, name: string | null, state: Object | null, account_ref: string | null, created: Date, updated: Date) {
    this.id = id
    this.name = name
    this.state = state
    this.account_ref = account_ref
    this.created = created
    this.updated = updated
  }

  async initialize() {
    const flowNode = new FlowNode(null, null, null, null, null, new Date(), new Date(), null, null, null, null, null, null)
    await flowNode.initialize()
    const flowNodeIntegration = new FlowNodeIntegration(null, null, null, null)
    await flowNodeIntegration.initialize()
    const flowModuleAction = new FlowModuleAction(null, null, null, null, null, null)
    await flowModuleAction.initialize()
  }
}

export class FlowNode {
  id: number | null
  flow: Flow | null
  module: string | null
  module_type: string | null
  module_sub_type: string | null
  created: Date
  updated: Date
  state: Object | null
  next_node: FlowNode | null
  parent_node: FlowNode | null
  account_ref: string | null
  flow_module_action: FlowModuleAction | null
  position: Object | null

  constructor(
    id: number | null, flow: Flow | null, module: string | null, 
    module_type: string | null, module_sub_type: string | null, created: Date, 
    updated: Date, state: Object | null, next_node: FlowNode | null, 
    parent_node: FlowNode | null, account_ref: string | null, 
    flow_module_action: FlowModuleAction | null, position: Object | null
  ) {
    this.id = id
    this.flow = flow
    this.module = module
    this.module_type = module_type
    this.module_sub_type = module_sub_type
    this.created = created
    this.updated = updated
    this.state = state
    this.next_node = next_node
    this.parent_node = parent_node
    this.account_ref = account_ref
    this.flow_module_action = flow_module_action
    this.position = position
  }

  async initialize() {
    const uStore = userStore()
    const config = useRuntimeConfig()
    const req = await fetch(`${config.public.MYTHOS_RUNNER}/flow/nodes`, {
      headers: { idToken: uStore.user?.idToken || '' }
    })
    const res = await req.json()

    if (res && res.nodes) {
      const flow_store = flowStore()
      flow_store.flowNodes = []

      res.nodes.forEach((f: FlowNode) => {
        flow_store.flowNodes.push(
          new FlowNode(
            f.id, 
            f.flow, 
            f.module, 
            f.module_type, 
            f.module_sub_type,
            f.created, 
            f.updated, 
            f.state, 
            f.next_node,
            f.parent_node, 
            f.account_ref, 
            f.flow_module_action,
            f.position
          )
        )
      })
    }
  }
}

export class FlowNodeIntegration {
  id: number | null
  flow_node: FlowNode | null
  integration: Platform | null
  integration_ref: string | null

  constructor(id: number | null, flow_node: FlowNode | null, integration: Platform | null, integration_ref: string | null) {
    this.id = id
    this.flow_node = flow_node
    this.integration = integration
    this.integration_ref = integration_ref
  }

  async initialize() {
    const uStore = userStore()
    const config = useRuntimeConfig()

    const req = await fetch(`${config.public.MYTHOS_RUNNER}/flow/nodes/integrations`, {
      headers: { idToken: uStore.user?.idToken || '' }
    })
    const res = await req.json()
    
    if (res && res.integrations) {
      const flow_store = flowStore()
      flow_store.flowNodeIntegrations = []
      
      res.integrations.forEach((f: FlowNodeIntegration) => {
        flow_store.flowNodeIntegrations.push(new FlowNodeIntegration(f.id, f.flow_node, f.integration, f.integration_ref))
      })
    }
  }
}